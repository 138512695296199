export const locale = {
  台北鳥會例行活動: 'Regular activities of the WBST',
  例行活動: 'Regular activity',
  駐站解說: 'On-site commentary',
  歷史記錄查詢: 'Historical record inquiry',
  例行路線查詢: 'Regular route inquiry',
  大型活動資訊: 'Information on major activity',
  關渡自然公園即時鳥況: 'Birding information on Guandu Nature Park  ',
  志工排班查詢: 'Volunteer scheduling inquiry',
  活動報到個資設定: 'Personal information setting',
  活動簽到表單: 'Activity sign-in form',
  分享APP: 'Share the App',
  小額捐款: 'Micro-Donations',
  相關網站: 'Relevant websites',
  深色模式: 'Dark mode',
  路線: 'Route',
  集合時間: 'Gathering time',
  集合地點: 'Gathering location',
  解說員: 'Tour leader',
  __msg_alert__:
    "If the activity is canceled or the route is changed due to typhoons, heavy rain, or other uncontrollable factors, it will be announced one working day in advance and simultaneously announced on the WBST's official website, Facebook page, and WBST App. Before heading out, please confirm whether there are any changes to the activity.",
  eBird熱點資訊: 'eBird hotspot information',
  賞鳥綠地圖: 'Bird Watching Green Map',
  __msg_1973999__:
    'Present the barcode or inform the WBSTdonation code 1973999',
  小額信用卡: 'Micro-donation via credit card',
  活動簽到: 'Activity check-in',
  __msg_install__: 'Install on desktop for easy access anytime',
  安裝: 'Install',
  取消: 'Cancel',
  __msg_install_ios__:
    "For iOS users, please open in Safari and select 'Share'",
  加入主畫面: 'Add to Home Screen',
  __msg_limit__: 'Limited capacity event, advance registration required',
  __msg_realname_help__:
    'Afterward, the personal information will be automatically filled in the next activity check-in. Family members can also set up multiple sets of information',
  姓名: 'Name',
  __msg_phone__: 'Phone number (both mobile and landline are acceptable',
  __msg_member__: 'Are you a member of the WBST (Wild Bird Society of Taipei)',
  是: 'Yes',
  否: 'No',
  存檔: 'Save',
  發票愛心碼: 'Charity Code for invoice',
  資料更新中: 'Data updating',
  更新資料: 'Update data',
  新增個人資料: 'Add personal information',
  關渡自然公園: 'Guandu Nature Park',
  芝山綠園: 'Zhishan Cultural and Ecological Garden',
  大安森林公園: 'Daan Park.',
  賞鳥記錄: 'Bird watching record',
  本週活動尚未確認: 'This week\'s event has not been confirmed yet',
  使用小秘訣: 'Tips for use'
}
