export const locale = {
  台北鳥會例行活動: '台北鳥會例行活動',
  例行活動: '例行活動',
  駐站解說: '駐站解說',
  歷史記錄查詢: '歷史記錄查詢',
  例行路線查詢: '例行路線查詢',
  大型活動資訊: '大型活動資訊',
  關渡自然公園即時鳥況: '關渡自然公園即時鳥況',
  志工排班查詢: '志工排班查詢',
  活動報到個資設定: '活動報到個資設定',
  活動簽到表單: '活動簽到表單',
  分享APP: '分享APP',
  小額捐款: '小額捐款',
  相關網站: '相關網站',
  深色模式: '深色模式',
  路線: '路線',
  集合時間: '集合時間',
  集合地點: '集合地點',
  解說員: '解說員',
  __msg_alert__:
    '如遇颱風豪雨或不可抗拒因素取消活動或變更路線，將於前一工作日公告同步公告於台北鳥會官網與FB粉專及北鳥例行APP，出門前請確認活動是否異動。',
  eBird熱點資訊: 'eBird熱點資訊',
  賞鳥綠地圖: '賞鳥綠地圖',
  __msg_1973999__: '出示條碼或告知鳥會捐贈碼1973999',
  小額信用卡: '小額信用卡',
  活動簽到: '活動簽到',
  __msg_install__: '安裝至桌面,隨時可以查詢',
  安裝: '安裝',
  取消: '取消',
  __msg_install_ios__: 'iOS使用者, 請使用Safari開啟, 選擇分享後',
  加入主畫面: '加入主畫面',
  __msg_limit__: '人數限定場，需事先報名',
  __msg_realname_help__:
    '設定後,下次執行活動簽到會自動帶入個人資料。家庭成員也可以設定多組資料。',
  姓名: '姓名',
  __msg_phone__: '電話號碼(手機/市話皆可)',
  __msg_member__: '是否為台北鳥會會員？',
  是: '是',
  否: '否',
  存檔: '存檔',
  發票愛心碼: '發票愛心碼',
  資料更新中: '資料更新中',
  更新資料: '更新資料',
  新增個人資料: '新增個人資料',
  關渡自然公園: '關渡自然公園',
  芝山綠園: '芝山綠園',
  大安森林公園: '大安森林公園',
  賞鳥記錄: '賞鳥記錄',
  本週活動尚未確認: '本週活動尚未確認',
  使用小秘訣: '使用小秘訣'
}
